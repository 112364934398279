import ApiRequest from "../utils/apiRequest"

export default class ScanLocation {
    static getAll = async () => {
        return await ApiRequest.set("v1/scan_locations", "GET")
    }

    static getUsers = async () => {
        return await ApiRequest.set("v1/scan_locations/users", "GET")
    }

    static create = async (body) => {
        return await ApiRequest.set("v1/scan_location", "POST", body)
    }

    static edit = async (id, body) => {
        return await ApiRequest.set("v1/scan_location/" + id, "PUT", body)
    }

    static scan = async (uuid) => {
        return await ApiRequest.set("v1/scan_location/scan/" + uuid, "GET")
    }

    static scanUser = async (body) => {
        return await ApiRequest.set("v1/scan_location/admin_scan", "POST", body)
    }

    static delete = async (id) => {
        return await ApiRequest.set("v1/scan_location/" + id, "DELETE")
    }

}