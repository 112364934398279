// component
import SvgColor from '../../../components/svg-color';
import { Icon } from '@iconify/react';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon('ic_analytics'),
  },

  // {
  //   title: 'Session',
  //   path: '/session',
  //   icon: <Icon icon="ic:baseline-event" />,
  // },
  // {
  //   title: 'Scan Location',
  //   path: '/scan-location',
  //   icon: <Icon icon="ic:baseline-qr-code" />,
  // },
  // {
  //   title: 'QR Location',
  //   path: '/qr-location',
  //   icon: <Icon icon="icon-park-solid:booth" />,
  // },
  // {
  //   title: 'booth',
  //   path: '/booth',
  //   icon: <Icon icon="icon-park-solid:booth" />,
  // },
  {
    title: 'user',
    path: '/user',
    icon: <Icon icon="mdi:user-multiple" />,
  },
  {
    title: 'Scanned Users',
    path: '/scanned-users',
    icon: <Icon icon="ic:baseline-qr-code" />,
  },
  {
    title: 'Coaching Clinic',
    path: '/coaching-clinic',
    icon: <Icon icon="uis:schedule" />,
  },
  // {
  //   title: 'admin',
  //   path: '/admin',
  //   icon: <Icon icon="eos-icons:admin" />,
  // },

  // {
  //   title: 'Change Password',
  //   path: '/profile',
  //   icon: <Icon icon="mdi:lock" />,
  // },
  {
    title: 'Logout',
    path: '/logout',
    icon: <Icon icon="ri:logout-circle-r-line" />,
  },

];

export default navConfig;
