import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import {
  Link,
  Container,
  Typography,
  Divider,
  Stack,
  Button,
  TextField,
  InputAdornment,
  IconButton, Checkbox
} from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import Admin from "../api/admin";
import { useNavigate } from "react-router-dom";
import { message } from 'antd';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {

  const navigate = useNavigate()

  const mdUp = useResponsive('up', 'md');
  const [showPassword, setShowPassword] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const login = async () => {
    try {
      let res = await Admin.login({
        username: username,
        password: password
      })
      console.log('telah login', res)
      localStorage.setItem("token", res.token)
      localStorage.setItem('userData', JSON.stringify(res))
      // window.location.reload()

      if (res.token) {
        navigate("/dashboard")
      }

    } catch (error) {
      console.log(error)
      message.error('User Not Found')
    }
  }
  const handleOnEnter = (e) => {

    if (e.key === 'Enter') {
      login()
    }
  }



  return (
    <>
      <Helmet>
        <title> Login | Indonesia 4.0 </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom className={"mb-4"}>
              SIGN IN
            </Typography>

            <Stack spacing={3} className={"mb-4"}>
              <TextField
                onKeyPress={(e) => {
                  handleOnEnter(e)
                }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                name="username" label="Username" />

              <TextField
                onKeyPress={(e) => {
                  handleOnEnter(e)
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={async () => {
              await login()
            }}>
              Login
            </LoadingButton>

            <div style={{
              fontSize: "0.85em",
              textAlign: "center",
              marginTop: 10
            }}>Powered By <b style={{ fontFamily: "League Spartan" }}>INTELLIVENT</b></div>

          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
