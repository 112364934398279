import {

  Button,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { Form, Modal } from "react-bootstrap";
import nameTagTemplate from "../../assets/nametagtemplate_black.jpg"
import { useRef, useState } from "react";
import { QRCode } from 'react-qrcode-logo';
import logo from "../../assets/qrlogo.jpg"
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment"

import pdfMake, { tableLayouts } from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import printJS from "print-js";

pdfMake.vfs = pdfFonts.pdfMake.vfs;


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function UserTagModal({ data, onClose }) {

  let formattedData = data ? data : {}
  let { name, title, code, jobTitle, companyName } = formattedData
  console.log('isi ', formattedData)
  const [nameTagWidth, setNameTagWidth] = useState(0)
  const [isPrinting, setIsPrinting] = useState(false)
  const printContainerRef = useRef(null)

  const handleClose = (isRefresh = true) => {
    onClose(isRefresh)
  }

  const print = () => {

  }

  const generatePDF = async (isPrintMode) => {

    const canvas = await html2canvas(printContainerRef.current, {
      scale: 1.1,
      quality: 4,
      dpi: 320
    });

    let imgWidth = 216;
    const image = canvas.toDataURL("image/jpeg");

    const pdf = new jsPDF('p', 'mm', [105, 148]);
    await pdf.addImage(image, 'JPEG', 0, 0, 105, 148);
    // pdf.output('dataurlnewwindow');

    if (isPrintMode) {
      // pdf.autoPrint({variant : "javascript"});
      let output = pdf.output(`bloburl`)

      printJS(output)

      handleClose()

      // console.log("output", output)
      // window.open(output)

    } else {
      await pdf.save(`${name}_NC${moment().format("DDMMYYYYHH:mm")}.pdf`);
    }
  }

  const generatePDFV2 = async () => {

    const canvas = await html2canvas(printContainerRef.current, {
      scale: 1.1,
      quality: 4,
      dpi: 320
    });

    const image = canvas.toDataURL("image/jpeg");



    // var tableArray = [];
    // tableArray[0] = [{text : "No", style : "left"}, "Name", {text : "Price", style : "right"}];
    // var docDefinition = {
    //   pageSize: {
    //     width : 300,
    //     height : "auto",
    //   },
    //   pageMargins: [0,0,0,0],
    //   content: [
    //     {
    //       columns: [
    //         {
    //           image: image, width: 290,
    //           margin: [0, 0, 0, 0],
    //         },
    //         // [
    //         //   { text: "Gerai Lengkong", style: "bold" },
    //         //   { text: "Ruko Golden Square Blok GS No.5, Jalan Raya Ciater, Rw. Mekar Jaya, Kec. Serpong, Kota Tangerang Selatan, Banten 15310" },
    //         //   { text: "Telp: 0819-3387-4458"},
    //         // ]
    //       ]
    //     },
    //   ],
    //   defaultStyle: {
    //     //lineHeight: 1.6,
    //     //alignment: "center",
    //     columnGap: 20,
    //   },
    //   styles: {
    //     header: {
    //       fontSize: 14,
    //       bold: true,
    //       alignment: "center",
    //       lineHeight: 1.6,
    //     },
    //     header2: {
    //       fontSize: 18,
    //       alignment: "center",
    //       lineHeight: 1.6,
    //     },
    //     right: {
    //       alignment: "right",
    //     },
    //     bold: {
    //       bold: true,
    //     },
    //     center: {
    //       alignment: "center",
    //     },
    //     table: {
    //       width: "100%",
    //       fontSize :16
    //     },
    //     center_italic: {
    //       italics: true,
    //       alignment: "center",
    //       fontSize : 13
    //     }
    //   },
    // };
    // pdfMake.createPdf(docDefinition, tableLayouts).print();
  }


  const handleTextLength = (str, maxLen, splited) => {
    if (str?.length > maxLen) {
      return str?.split(' ').slice(0, splited).join(' ')
    } else {
      return str
    }

  }

  return (
    <Modal
      contentClassName={"name-tag-modal"}
      className='custom-modal' show={!!data} backdrop="static" keyboard={false}>
      <Modal.Body>
        <div>
          <div ref={printContainerRef}>
            <div
              className={"d-flex flex-column align-items-center justify-content-center"}
              ref={(ref) => {
                if (ref) {
                  setNameTagWidth(ref.clientWidth)
                }
              }}
              style={{
                // background: `url(${nameTagTemplate})`,
                backgroundSize: "contain",
                backgroundRepeatX: "no-repeat",
                backgroundRepeatY: "no-repeat",
                width: "100%",
                height: 1.4095 * nameTagWidth,
              }}
            >
              <div
                className={"d-flex flex-column align-items-center justify-content-center"}
                style={{
                  background: "white",
                  width: 200,
                  height: 0.3 * nameTagWidth,
                  borderRadius: 0.02 * nameTagWidth,
                  position: 'relative',
                  bottom: 55,
                  right: 3
                }}
              >
                <QRCode
                  size={180}
                  style={{ height: "auto", maxWidth: "100%", width: "100%", display:'none'}}
                  value={code}
                  viewBox={`0 0 256 256`}
                  ecLevel='H'
              
                />
                {/*<div style={{*/}
                {/*  width : "100%",*/}
                {/*  height : 300,*/}
                {/*  background : `url("https://wellsource.sgp1.digitaloceanspaces.com/intellivent-register/qr/qr_Damocles_1683262576704.png")`*/}
                {/*}}>*/}

                {/*</div>*/}
                {/*<img*/}
                {/*  style={{*/}
                {/*    width: "100%",*/}
                {/*    objectFit: "contain",*/}
                {/*    borderRadius: 0.02 * nameTagWidth*/}
                {/*  }}*/}
                {/*  src={"https://wellsource.sgp1.digitaloceanspaces.com/intellivent-register/qr/qr_Damocles_1683262576704.png"}/>*/}

                <div style={{
                  fontSize: name?.length > 26 ? nameTagWidth * 0.03 : 19,
                  // marginTop: nameTagWidth * 0.01,
                  // fontSize: nameTagWidth * 0.025,
                  textAlign:'center',
                  width:220,
                  fontWeight: 'bolder'
                }}>
                  {handleTextLength(name, 26, 3)}
                </div>
                <div style={{
                  color: "black",
                  // fontWeight: "bold",
                  fontSize: nameTagWidth * 0.033,
                  // marginTop: nameTagWidth * 0.01,
                  textTransform: "uppercase",
                  textAlign: "center",
                  textOverflow: "clip",
                  // whiteSpace: "nowrap",
                  width: 0.6 * nameTagWidth,
                  fontWeight: 500,
                  maxWidth: "100%",
                  // overflow : "hidden",
                }}>

                  {handleTextLength(companyName, 20, 4)}
                </div>
                <div style={{
                  color: "black",
                  fontSize: nameTagWidth * 0.025,
                  marginTop: nameTagWidth * 0.050,
                  textAlign: 'center',
                  textTransform: "uppercase",
                  fontWeight: 500
                }}>
                  {jobTitle}
                </div>

              </div>
            </div>

            <div className='usertag-roles'>EXHIBITOR</div>
          </div>

          <div className={"d-flex flex-row justify-content-end mt-2"}>

            <Button className='ml-3 me-2' size="sm" onClick={() => {
              handleClose()
            }} variant="outlined">
              Close
            </Button>
            <Button className='ml-3 me-2' size="sm" onClick={() => {
              generatePDF()
            }} variant="outlined">
              Download
            </Button>
            <Button className='ml-3' size="sm" onClick={() => {
              setIsPrinting(true)
              generatePDF(true)
              // generatePDFV2()
            }} variant="contained">
              Print
            </Button>

          </div>
        </div>
      </Modal.Body>
    </Modal>

  )
}
